export enum HttpMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete',
}

export enum AccountType {
  NORMAL = 'NORMAL',
  ADMIN = 'ADMIN',
}

export enum CareerYearType {
  대학생 = 'U',
  취준생 = 'R',
  신입_3년차 = 'N',
  _3년차_이상 = 'S',
}

export enum QnaListType {
  ALL = 'ALL',
  ME = 'ME',
}
